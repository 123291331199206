import "./ActionBar.css";
import {
    ActionBar as EdenActionBar,
    X,
    Facebook,
    WhatsApp,
    Mail,
} from "@churchofjesuschrist/eden-action-bar";

const ActionBar = () => {
    return (
        <EdenActionBar className="ActionBar">
            <X />
            <Facebook />
            <WhatsApp />
            <Mail />
        </EdenActionBar>
    );
};

export default ActionBar;
