import { Fragment, useContext } from "react";
import { Helmet } from "react-helmet";
import { get } from "@churchofjesuschrist/universal-env";
import StyleDependentContent from "../StyleDependentContent";
import GeneralContext from "../GeneralContext";

const { IPS_CDN_URL, APP_URL } = get();

const ReaderHelmet = ({
    links,
    meta = { pageAttributes: {} },
    noIndex,
    pageMeta = {},
    scripts,
}) => {
    let { crossLinkMode } = useContext(GeneralContext);

    return (
        <Fragment>
            <StyleDependentContent links={links} />
            <Helmet>
                {!crossLinkMode && [
                    <title key="title">{meta.title}</title>,
                    <meta
                        key="Search.doc-aid"
                        name="Search.doc-aid"
                        content={meta.pageAttributes["data-aid"]}
                    />,
                    noIndex && (
                        <meta key="robots" name="robots" content="noindex" />
                    ),

                    Object.entries(pageMeta)
                        .map(([name, content]) => (
                            <meta key={name} name={name} content={content} />
                        ))
                        .flat(),

                    meta.ogTagImageUrl && (
                        <meta
                            key="og:image"
                            property="og:image"
                            content={meta.ogTagImageUrl}
                        />
                    ),
                    <meta
                        key="og:title"
                        property="og:title"
                        content={meta.title}
                    />,
                    <meta key="og:type" property="og:type" content="website" />,
                    <meta
                        key="og:url"
                        property="og:url"
                        content={`${APP_URL}/${meta.pageAttributes.lang}${meta.pageAttributes["data-uri"]}`}
                    />,

                    <link
                        key="canonical"
                        rel="canonical"
                        href={`${APP_URL}${meta.canonicalUrl}`}
                    />,
                ]}
                <script type="application/ld+json">
                    {meta.structuredData}
                </script>
                {scripts.map((script) => (
                    <script
                        {...script}
                        key={script.src}
                        src={`${IPS_CDN_URL}/styles/${script.src}`}
                        type="text/javascript"
                    />
                ))}
            </Helmet>
        </Fragment>
    );
};

export default ReaderHelmet;
