import { Component, createRef } from "react";
import debounce from "debounce";

class HeightProvider extends Component {
    static observedElements = [];

    static handleResize = debounce(
        () => HeightProvider.observedElements.forEach((handler) => handler()),
        100
    );

    constructor(props) {
        super(props);

        this.componentRef = props.ref || createRef();
        this.state = {};
    }

    componentDidMount() {
        if (HeightProvider.observedElements.length === 0) {
            window.addEventListener("resize", HeightProvider.handleResize);
        }

        HeightProvider.observedElements.push(this.handleResize);

        this.handleResize();
    }

    componentWillUnmount() {
        HeightProvider.observedElements =
            HeightProvider.observedElements.filter(
                (ref) => ref !== this.handleResize
            );

        if (HeightProvider.observedElements.length === 0) {
            window.removeEventListener("resize", HeightProvider.handleResize);
        }
    }

    handleResize = () => {
        let height = `${
            this.componentRef.current.getBoundingClientRect().height
        }px`;

        this.state.height !== height && this.setState({ height });
    };

    render() {
        const { Component, style, ...props } = this.props;

        const { height } = this.state;

        return (
            <Component
                {...props}
                ref={this.componentRef}
                style={{ ...style, "--height": height }}
            />
        );
    }
}

export default HeightProvider;
