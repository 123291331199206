import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "./BitmovinVideoPlayer.css";
import { Bitmovin } from "@churchofjesuschrist/eden-video-player";
import classes from "classnames";
import handleClickAndEnterKey from "../../../util/handle-click-and-enter-key";
import { Video as VideoIcon } from "@churchofjesuschrist/eden-icons";
import { selectImagePreferences } from "../../selectors";
import { useSelectI18nStringById } from "../../../util/custom-hooks";
import { Text3, Text4 } from "@churchofjesuschrist/eden-text";

const BitmovinVideoPlayer = ({
    className,
    description,
    endTime,
    isAssociatedMedia,
    isReferenceMedia,
    posterId,
    posterUrl,
    refId,
    startTime,
    title,
    durationLabel,
}) => {
    const [show, setShow] = useState(false);
    const [started, setStarted] = useState(false);
    const disableVideos = useSelector(selectImagePreferences);
    const selectI18nStringById = useSelectI18nStringById();
    const shouldShow = (disableVideos && show) || !disableVideos;
    const handleShow = () => setShow(true);
    const posterReference = posterId || encodeURIComponent(posterUrl);
    const imageSizes =
        isAssociatedMedia || isReferenceMedia
            ? "250px"
            : "(max-width: 400px) 320px, 640px";
    const imageSrcSet = isAssociatedMedia
        ? `https://www.churchofjesuschrist.org/imgs/${posterReference}/full/!250,/0/default 250w`
        : `
            https://www.churchofjesuschrist.org/imgs/${posterReference}/full/!320,/0/default 320w,
            https://www.churchofjesuschrist.org/imgs/${posterReference}/full/!640,/0/default 640w
        `;
    const [playbackSpeed, setPlaybackSpeed] = useState(
        typeof window !== "undefined"
            ? localStorage.getItem("glo-video-playbackSpeed")
            : 1
    );
    const [audioLanguage, setAudioLanguage] = useState(
        typeof window !== "undefined"
            ? localStorage.getItem("glo-video-audioLanguage")
            : ""
    );
    const [subtitleLanguage, setSubtitleLanguage] = useState(
        typeof window !== "undefined"
            ? localStorage.getItem("glo-video-subtitleLanguage")
            : ""
    );
    const [videoQuality, setVideoQuality] = useState(
        typeof window !== "undefined"
            ? localStorage.getItem("glo-video-videoQuality")
            : ""
    );

    // When there are multiple videos on the same page, we need to make sure that the value set on one is passed to the others. If you change the audio/subtitle language, you would likely want the next video to also have that audio/subtitle language to not have to do it over and over. We need to do some extra trickery to allow setting a value in one video to apply to other videos on the same page. This will only apply to videos that haven't already started playing, not changing players that have already loaded.
    const publishChange = (type, value) => {
        // send a message out for other players to receive
        const message = { type: `${type}Change`, value, source: "glo-video" };
        window.postMessage(message, window.location.origin);
        // update the local storage for the current player
        localStorage.setItem(`glo-video-${type}`, value);
    };
    useEffect(() => {
        const handleReceiveMessage = (event) => {
            if (event.data.source !== "glo-video" || started) {
                // Ignore messages from different origins for security
                return;
            }
            const { type, value } = event.data;
            switch (type) {
                case "audioLanguageChange":
                    setAudioLanguage(value);
                    break;
                case "subtitleLanguageChange":
                    setSubtitleLanguage(value);
                    break;
                case "videoQualityChange":
                    setVideoQuality(value);
                    break;
                case "playbackSpeedChange":
                    setPlaybackSpeed(value);
                    break;
                default:
            }
        };

        window.addEventListener("message", handleReceiveMessage);
        return () =>
            window.removeEventListener("message", handleReceiveMessage);
    }, [started, refId]);

    return shouldShow ? (
        <Bitmovin
            accountId="1241706627001"
            className={classes(className, styles.video, "video")}
            endTime={endTime}
            playerLoadErrorMessage={selectI18nStringById(
                "playerLoadErrorMessage"
            )}
            playLabel={`
                ${selectI18nStringById("playLabel")} - ${
                title || selectI18nStringById("videoText")
            }
            `}
            poster={
                <img
                    alt=""
                    className={styles.posterFallback}
                    sizes={imageSizes}
                    srcSet={imageSrcSet}
                    loading="lazy"
                    src={`https://www.churchofjesuschrist.org/imgs/${posterReference}/full/!250,/0/default`}
                />
            }
            startTime={startTime}
            videoId={`ref:${refId}`}
            audioLanguage={audioLanguage}
            subtitleLanguage={subtitleLanguage}
            videoQuality={parseInt(videoQuality, 10)}
            playbackSpeed={parseInt(playbackSpeed, 10)}
            durationLabel={durationLabel}
            onPlay={() => {
                setStarted(true);
            }}
            onAudioLanguageChange={(value) => {
                publishChange("audioLanguage", value);
            }}
            onSubtitleLanguageChange={(value) => {
                publishChange("subtitleLanguage", value);
            }}
            onVideoQualityChange={(value) => {
                publishChange("videoQuality", value);
            }}
            onPlaybackSpeedChange={(value) => {
                publishChange("playbackSpeed", value);
            }}
        />
    ) : isAssociatedMedia ? (
        <button
            className={styles.isAssociatedMedia}
            onClick={handleClickAndEnterKey(handleShow)}
            onKeyPress={handleClickAndEnterKey(handleShow)}
        >
            <VideoIcon size="1.5rem" />
            <Text3 as="span" className={styles.i18nString}>
                {selectI18nStringById("videoText")}
            </Text3>
        </button>
    ) : (
        <button
            className={styles.loadVideoButton}
            onClick={handleClickAndEnterKey(handleShow)}
            onKeyPress={handleClickAndEnterKey(handleShow)}
        >
            <div className={styles.innerButton}>
                <div className={styles.innerFlex}>
                    <VideoIcon size="1.5rem" />
                    <span className={styles.text}>
                        <Text3 as="span" className={styles.i18nString}>
                            {selectI18nStringById("videoText")}
                        </Text3>
                        <Text3 as="span" className={styles.title}>
                            {title}
                        </Text3>
                        <Text4 className={styles.description}>
                            {description}
                        </Text4>
                    </span>
                </div>
            </div>
        </button>
    );
};

export default BitmovinVideoPlayer;
