import styles from "./OutOfServiceMessage.css";
import classes from "classnames";
import { useSelectI18nStringById } from "../../../util/custom-hooks";

const OutOfServiceMessage = ({
    className,
    Component = "span",
    selectI18nStringById,
    ...props
}) => (
    <Component className={classes(styles.message, className)} {...props}>
        {selectI18nStringById("annotationsOutOfServiceText")}
    </Component>
);

const OutOfServiceMessageContainer = (props) => {
    const selectI18nStringById = useSelectI18nStringById();

    return (
        <OutOfServiceMessage
            selectI18nStringById={selectI18nStringById}
            {...props}
        />
    );
};

export default OutOfServiceMessageContainer;
