import Fuse from "fuse.js";
import { getCookies, eraseCookie } from "../util/cookies";

export const handleSearch = (content) => {
    const searchOrigin = getCookies().searchSnippet;

    if (!searchOrigin) return;

    const html = content.content.body;
    const htmlHolder = document.createElement("div");
    htmlHolder.innerHTML = html;

    const headerElements = htmlHolder
        .querySelector("header")
        ?.querySelectorAll("p, h1, h2, h3, h4, h5, h6");
    const bodyElements = htmlHolder
        .querySelector(".body-block")
        ?.querySelectorAll("p, h1, h2, h3, h4, h5, h6");
    const elements = headerElements &&
        bodyElements && [...headerElements, ...bodyElements];

    if (!elements) return;

    const options = elements
        .filter((tag) => tag.id)
        .map((tag) => ({ id: tag.id, text: tag.innerText || tag.textContent }));

    if (!options) return;

    const fuseOptions = {
        ignoreLocation: true,
        keys: ["text"],
        includeScore: true,
    };
    const fuse = new Fuse(options, fuseOptions);

    const [searchQuery, searchSnippet] = searchOrigin.split("***");

    const queryResults = searchQuery ? fuse.search(searchQuery) : [];
    const snippetResults = searchSnippet ? fuse.search(searchSnippet) : [];

    let bestResult = { score: 2 };

    queryResults.forEach((queryResult) => {
        const snippetResult = snippetResults.find(
            (result) => result.item.id === queryResult?.item.id
        );

        // 0 - perfect match, 1 - mismatch
        const score = queryResult.score + (snippetResult?.score || 1);

        if (bestResult.score > score) {
            bestResult = { id: queryResult.item.id, score };
        }
    });

    if (!bestResult.id) return;

    window.location.hash = bestResult.id;
    highlight(
        htmlHolder.querySelector(`#${bestResult.id}`),
        searchQuery.split(" ")
    );

    eraseCookie("searchSnippet", content.uri);

    return htmlHolder.innerHTML;
};

//https://stackoverflow.com/questions/8644428/how-to-highlight-text-using-javascript#:~:text=Following%20is%20my%20attempt%3A
const highlight = (element, keywords) => {
    // Sort longer matches first to avoid highlighting keywords within keywords.
    keywords.sort((a, b) => b.length - a.length);

    // Add in regex word bounds - will only match full words
    const constrainedKeywords = keywords.map((word) => `\\b${word}\\b`);
    const keywordRegex = RegExp(constrainedKeywords.join("|"), "gi");

    Array.from(element.childNodes).forEach((child) => {
        if (child.nodeType !== 3) {
            // not a text node
            highlight(child, keywords);
        } else if (keywordRegex.test(child.textContent)) {
            let lastIdx = 0;
            const frag = document.createDocumentFragment();
            child.textContent.replace(keywordRegex, (match, idx) => {
                const part = document.createTextNode(
                    child.textContent.slice(lastIdx, idx)
                );
                const highlighted = document.createElement("span");

                highlighted.textContent = match;
                highlighted.classList.add("searchHighlight");

                frag.appendChild(part);
                frag.appendChild(highlighted);

                lastIdx = idx + match.length;
            });
            const end = document.createTextNode(
                child.textContent.slice(lastIdx)
            );
            frag.appendChild(end);
            child.parentNode.replaceChild(frag, child);
        }
    });
};
