import styles from "./ModalControls.css";
import classes from "classnames";
import {
    LoadingSpinner as LoadingSpinnerIcon,
    Clear as ClearIcon,
} from "@churchofjesuschrist/eden-icons";
import { CSSTransition } from "react-transition-group";
import { GhostButton, IconButton } from "../../theming/components/eden-buttons";

const saveButtonClassNames = {
    // appear: 'my-appear',
    // appearActive: 'my-active-appear',
    // appearDone: 'my-done-appear',
    enter: styles["save-button-enter"],
    enterActive: styles["save-button-enter-active"],
    enterDone: styles["save-button-enter-done"],
    exit: styles["save-button-exit"],
    exitActive: styles["save-button-exit-active"],
    exitDone: styles["save-button-exit-done"],
};

const buttons = [
    {
        key: "save",
        string: "editorSave",
        classNames: [],
        timeout: 200,
    },
    {
        key: "saved",
        string: "editorSaved",
        classNames: [styles.savedButton],
        timeout: 200,
    },
    {
        key: "saving",
        string: "editorSaving",
        classNames: [],
        timeout: 200,
    },
    {
        key: "error",
        string: "editorError",
        classNames: [],
        timeout: 200,
    },
];

const ModalControls = ({
    className,
    close,
    disableSave,
    saveAll,
    saveState,
    selectI18nStringById,
}) => (
    <div className={classes(styles.modalControls, className)}>
        {buttons.map(({ classNames, key, string, timeout }) => (
            <CSSTransition
                className={classes(styles.saveButton, ...classNames)}
                classNames={saveButtonClassNames} //classNames is required for CSSTransition. It's the prefix for the many classes it adds like, "-exit" or "-exit-active"
                in={saveState === key}
                key={key}
                mountOnEnter
                timeout={timeout}
            >
                {key !== "saving" ? (
                    <GhostButton
                        disabled={disableSave}
                        onClick={saveAll}
                        title={selectI18nStringById(string)}
                        small
                        data-testid="cross-link-save"
                    >
                        {selectI18nStringById(string)}
                    </GhostButton>
                ) : (
                    <IconButton size="small">
                        <LoadingSpinnerIcon size="1em" />
                    </IconButton>
                )}
            </CSSTransition>
        ))}

        <IconButton
            className={styles.closeButton}
            onClick={close}
            title={selectI18nStringById("close")}
            size="small"
            data-testid="cross-link-close"
        >
            <ClearIcon />
        </IconButton>
    </div>
);

export default ModalControls;
