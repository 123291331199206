import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import fatIconButtonFactory from "../utils/fatIconButtonFactory";
import { MoreMenuVertical } from "@churchofjesuschrist/eden-icons";
import { useSelectI18nStringById } from "../../../../util/custom-hooks";
import { setToolbarClosed } from "../../../actions/localSettings";

const MoreMenuButton = fatIconButtonFactory(MoreMenuVertical);

export const Closed = ({ changeView, setToolbarClosed }) => {
    const selectI18nStringById = useSelectI18nStringById();

    return (
        <MoreMenuButton
            onClick={() => {
                setToolbarClosed(false);
                changeView("DefaultToolbar");
            }}
            title={selectI18nStringById("annotationMenuOpenToolbarText")}
            data-testid="open-fat"
        />
    );
};

const ClosedContainer = (props) => {
    const dispatch = useDispatch();
    const actions = useMemo(
        () => bindActionCreators({ setToolbarClosed }, dispatch),
        [dispatch]
    );

    return <Closed {...actions} {...props} />;
};

export default ClosedContainer;
