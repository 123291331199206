import { useEffect, useRef } from "react";
import styles from "../Selectable.css";
import isMobile from "../../../../util/is-mobile";

const SelectionHandles = ({
    container,
    handleSelectEnd,
    onDragStart,
    parentRef,
    range,
    selection,
    setSelectionState,
    showHandles,
}) => {
    const endHandleRef = useRef();
    const startHandleRef = useRef();
    const dragState = useRef({ dragging: false });
    const SELECT_HANDLE_SIZE = isMobile.phone ? 1.3 : 1.2;

    useEffect(() => {
        showHandles ? handleShowHandles() : handleHideHandles();
    });

    /* ******** Handles VISUAL Functions Start ********* */

    const handleHideHandles = () => {
        startHandleRef.current.style.display = "none";
        endHandleRef.current.style.display = "none";
    };

    const isValidSelection = () => {
        if (parentRef) {
            return selection.containsNode(parentRef, true);
        }
    };

    const handleShowHandles = () => {
        if (isValidSelection() && !isMobile.apple.device) {
            const { left: containerLeft, top: containerTop } =
                container.getBoundingClientRect();
            const rangeRects = getValidSelectionRects();

            if (!rangeRects.length) {
                handleHideHandles();

                return;
            }

            const startHandleStyles = startHandleRef.current.style;
            const endHandleStyles = endHandleRef.current.style;

            startHandleStyles.height = SELECT_HANDLE_SIZE + "em";
            startHandleStyles.width = SELECT_HANDLE_SIZE + "em";
            startHandleStyles.left = `calc(${
                rangeRects[0].left - containerLeft
            }px - ${SELECT_HANDLE_SIZE}em)`;
            startHandleStyles.top = rangeRects[0].bottom - containerTop + "px";
            startHandleStyles.display = "block";

            endHandleStyles.height = SELECT_HANDLE_SIZE + "em";
            endHandleStyles.width = SELECT_HANDLE_SIZE + "em";
            endHandleStyles.left =
                rangeRects[rangeRects.length - 1].right - containerLeft + "px";
            endHandleStyles.top =
                rangeRects[rangeRects.length - 1].bottom - containerTop + "px";
            endHandleStyles.display = "block";
        }
    };

    const getValidSelectionRects = () => {
        const rangeRects = selection.getRangeAt(0).getClientRects();
        const { left: contentLeft, right: contentRight } = document
            .querySelector("[data-aid-version]")
            .getBoundingClientRect();

        const isValidRange = ({ left, right }) =>
            left >= contentLeft && right <= contentRight;

        return [...rangeRects].filter(isValidRange);
    };

    /* ******** Handles VISUAL Functions End ********* */

    /* ******** Handles DRAG Functions Start ********* */

    const handleDragStart = (event) => {
        let rect = event.currentTarget.getBoundingClientRect();

        dragState.current = {
            dragging: true,
            offsetX: event.clientX - rect.left,
            offsetY: event.clientY - rect.top,
            range: range,
            target: event.currentTarget,
        };

        dragState.current.target.setPointerCapture(event.pointerId);
        onDragStart();
    };

    const handleDrag = (event) => {
        if (dragState.current.dragging) {
            const { left: containerLeft, top: containerTop } =
                container.getBoundingClientRect();
            const { target, offsetX, offsetY } = dragState.current;

            target.style.left = event.clientX - offsetX - containerLeft + "px";
            target.style.top = event.clientY - offsetY - containerTop + "px";
            target.classList.add(styles.dragging);

            event.preventDefault();
            setRangeFromHandles(event);
        }
    };

    const handleDragEnd = (event) => {
        setSelectionState({ selectButtonDown: false });

        dragState.current.target.classList.remove(styles.dragging);
        dragState.current.target.releasePointerCapture(event.pointerId);
        handleSelectEnd(true);

        dragState.current = {
            dragging: false,
            offsetX: undefined,
            offsetY: undefined,
            range: undefined,
            target: undefined,
        };
    };

    const setRangeFromHandles = (event) => {
        const {
            offsetX: handleOffsetX,
            offsetY: handleOffsetY,
            target,
            range,
        } = dragState.current;
        let offsetNode, offset;

        let offsetX = event.clientX - handleOffsetX;
        let offsetY = event.clientY - handleOffsetY;

        if (target === startHandleRef.current) {
            offsetX += target.offsetWidth;
        }

        // The last caretPosition/Range often fails. This prevents the
        // undefined results from being used and causing unxepcted results
        try {
            if (document.caretPositionFromPoint)
                ({ offsetNode, offset } = document.caretPositionFromPoint(
                    offsetX,
                    offsetY
                ));
            else if (document.caretRangeFromPoint)
                ({ startContainer: offsetNode, startOffset: offset } =
                    document.caretRangeFromPoint(offsetX, offsetY));
        } catch (err) {
            return;
        }

        if (offsetNode && offset) {
            target === startHandleRef.current
                ? selection.setBaseAndExtent(
                      offsetNode,
                      offset,
                      range.endContainer,
                      range.endOffset
                  )
                : selection.setBaseAndExtent(
                      range.startContainer,
                      range.startOffset,
                      offsetNode,
                      offset
                  );
        }
    };

    /* ******** Handles DRAG Functions End ********* */

    const handleProps = {
        "touch-action": "none",
        onPointerDown: handleDragStart,
        onPointerMove: handleDrag,
        onPointerUp: handleDragEnd,
    };

    return (
        <>
            <div
                className={styles.startHandle}
                ref={startHandleRef}
                {...handleProps}
            ></div>
            <div
                className={styles.endHandle}
                ref={endHandleRef}
                {...handleProps}
            ></div>
        </>
    );
};

export default SelectionHandles;
