import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { addFolder, removeFolder } from "../../actions/annotations";
import {
    selectActiveHighlightAnnotation,
    selectFolders,
} from "../../selectors";
import { useSelectI18nStringById } from "../../../util/custom-hooks";
import { Notebook as NotebookIcon } from "@churchofjesuschrist/eden-icons";
import TypeaheadTagListModal from "../TypeaheadTagListModal";

export const NotebookModal = ({
    addFolder: addFolderAction,
    annotation,
    onClose,
    removeFolder,
    selectI18nStringById,
    folders = {},
    ...props
}) => {
    const items = useMemo(
        () =>
            Object.values(folders)
                .filter((folder) => !annotation?.folders.includes(folder))
                .map(({ name, id }) => ({ title: name, id })),
        [annotation, folders]
    );
    const addFolder = ({ title, id } = {}) =>
        addFolderAction({ name: title, id });

    // If there is no annotation id toggle/close the modal
    useEffect(() => {
        !annotation && onClose();
    }, [annotation, onClose]);

    return (
        <TypeaheadTagListModal
            addNewString={selectI18nStringById("annotationNewTagPostTitle")}
            icon={<NotebookIcon size="1.5rem" />}
            items={items}
            renderId={annotation?.id}
            onClose={() => onClose()}
            onSubmit={addFolder}
            placeholderText={selectI18nStringById("addNotebookPlaceholder")}
            removeTag={removeFolder}
            tagItems={annotation?.folders}
            title={selectI18nStringById("annotationAddToNotebookTitle")}
            testId="notebook"
            {...props}
        />
    );
};

const NotebookModalContainer = (props) => {
    const dispatch = useDispatch();
    const activeAnnotation = useSelector(selectActiveHighlightAnnotation);
    const selectI18nStringById = useSelectI18nStringById();
    const folders = useSelector(selectFolders);
    const actions = useMemo(
        () =>
            bindActionCreators(
                {
                    addFolder,
                    removeFolder,
                },
                dispatch
            ),
        [dispatch]
    );

    return (
        <NotebookModal
            annotation={activeAnnotation}
            selectI18nStringById={selectI18nStringById}
            folders={folders}
            {...actions}
            {...props}
        />
    );
};

export default NotebookModalContainer;
