import { Component } from "react";
import styles from "../TableOfContents.css";
import getScrollParent from "scrollparent";
import debounce from "debounce";
import { throttle } from "../../../../util/limiters";
import { withGeneralContext } from "../../GeneralContext";

class QuickNav extends Component {
    state = { scrollbarOffset: 0 };

    componentDidMount() {
        this.resizeNav();
        window.addEventListener("resize", this.resizeNav);
        document.addEventListener("pointermove", this.handlePointerMove);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeNav);
        document.removeEventListener("pointermove", this.handlePointerMove);
    }

    resizeNav = debounce(() => {
        let quickNavHeight = this.quickNav?.offsetHeight - 100;
        let navEntryHeight = this.quickNav?.firstElementChild.offsetHeight;
        let availableSlots = Math.floor(quickNavHeight / navEntryHeight);

        this.setState({ availableSlots });
    });

    fillNav() {
        const { entries } = this.props;
        const { availableSlots = entries.length } = this.state;

        const numToSkip = entries.length - availableSlots;
        const skipPerSlot = numToSkip / (availableSlots - 1);

        let skipThreshold = 0;

        return entries.reduce((acc, { title, uri }) => {
            if (skipThreshold > 0.5 && skipThreshold <= numToSkip) {
                --skipThreshold;
            } else {
                acc.push(
                    <li key={`quickNav_${uri.slice(1)}`}>
                        <button
                            onClick={this.handleClick(uri)}
                            onPointerEnter={this.handlePointerEnter}
                        >
                            {title}
                        </button>
                    </li>
                );
                skipThreshold += skipPerSlot;
            }

            return acc;
        }, []);
    }

    handlePointerDown = () => this.setState({ pointerDown: true });

    handlePointerMove = throttle(
        (e) =>
            e.buttons === 0 &&
            this.state.pointerDown &&
            this.setState({ pointerDown: false })
    );

    handlePointerEnter = (e) =>
        this.state.pointerDown && e.currentTarget.click();

    handleClick = (uri) => (e) => {
        let anchor = document.querySelector(uri),
            scrollParent = getScrollParent(anchor),
            closestSectionLabel = anchor.closest(
                `.${styles.sectionLabel} + ul`
            );

        scrollParent.scrollTop = anchor.offsetTop;
        e.nativeEvent.pointerType !== "mouse" && anchor.focus();

        if (closestSectionLabel) {
            scrollParent.scrollTop -= 30;
        }
    };

    render() {
        return (
            <ul
                className={styles.quickNav}
                data-testid={"quick-nav"}
                onPointerDown={this.handlePointerDown}
                // eslint-disable-next-line react/no-unknown-property
                touch-action="none"
                ref={(ref) => (this.quickNav = ref)}
            >
                {this.fillNav()}
            </ul>
        );
    }
}

QuickNav.defualtName = "QuickNav";

export default withGeneralContext(QuickNav);
