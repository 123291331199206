import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styles from "./TableOfContentsPanel.css";
import { useSelectI18nStringById } from "../../../util/custom-hooks";
import { selectToc } from "../../selectors";
import { prependAppBase } from "../../../util/uri-utils.js";
import isBrowser from "is-in-browser";

import QuickNav from "../TableOfContents/components/QuickNav";
import SidePanel from "../SidePanel";
import PanelHeader from "../PanelHeader";
import SmartLink from "../SmartLink";
import PanelContent from "../PanelContent";
import TableOfContents from "../TableOfContents";
import PanelPlatformFooter from "../PanelPlatformFooter";
import { iconButtonFactory } from "../IconButton";
import { ChevronLeft, Clear } from "@churchofjesuschrist/eden-icons";

const ChevronLeftButton = iconButtonFactory(ChevronLeft);
const CloseButton = iconButtonFactory(Clear);

export const TableOfContentsPanel = ({
    activeUri,
    book,
    className,
    handleVirtualScroll,
    libraryTitle,
    libraryUri,
    location,
    selectI18nStringById,
    tableOfContentsPanelOpen,
    toggleTableOfContentsPanel,
}) => {
    const quickNav = book.quickNav;
    const backButton = useRef();

    useEffect(() => {
        tableOfContentsPanelOpen && backButton.current.focus();
    }, [tableOfContentsPanelOpen]);

    const handleClick = (e) => {
        if (e.key === "Enter" || e.type === "click") {
            toggleTableOfContentsPanel(false, "close");
        }
    };

    return (
        <SidePanel className={className} Component="section">
            {tableOfContentsPanelOpen ? (
                <>
                    <PanelHeader className={styles.backToAll} testId={"toc"}>
                        <SmartLink
                            to={libraryUri}
                            className={styles.backText}
                            ref={backButton}
                        >
                            <ChevronLeftButton
                                className={styles.backIcon}
                                hiddenTitleText={selectI18nStringById(
                                    "backButtonText"
                                )}
                                data-testid={"toc-back"}
                            />
                            <span className={styles.backToLink}>
                                {libraryTitle}
                            </span>
                        </SmartLink>
                        <CloseButton
                            onClick={handleClick}
                            hiddenTitleText={selectI18nStringById(
                                "closePanelButtonText"
                            )}
                            data-testid={"toc-close"}
                        />
                    </PanelHeader>
                    <PanelContent
                        handleVirtualScroll={handleVirtualScroll}
                        testId={"toc"}
                    >
                        <div className={styles.tocWrapper}>
                            {isBrowser && quickNav && (
                                <QuickNav entries={quickNav} />
                            )}
                            <TableOfContents
                                book={book}
                                location={location}
                                activeUri={activeUri}
                            />
                        </div>
                        <PanelPlatformFooter
                            forceMobile={true}
                            panelFooter={true}
                        />
                        <div className={styles.tocPanelBottomBuffer}></div>
                    </PanelContent>
                </>
            ) : (
                // When the TOC isn't being rendered the footer needs to be or
                // it will have to render at the bottom of the page... which we don't want.
                <PanelPlatformFooter
                    className={styles.hiddenPlatformFooter}
                    forceMobile={true}
                />
            )}
        </SidePanel>
    );
};

const TableOfContentsPanelContainer = (props) => {
    const location = useLocation();
    const book = useSelector((state) => selectToc(state, location));
    const libraryTitle = book.parentNameOverride || book.parentName;
    const libraryUri =
        prependAppBase(book.parentUriOverride) ||
        prependAppBase(book.parentUri);
    const selectI18nStringById = useSelectI18nStringById();

    return (
        <TableOfContentsPanel
            book={book}
            libraryTitle={libraryTitle}
            libraryUri={libraryUri}
            location={location}
            selectI18nStringById={selectI18nStringById}
            {...props}
        />
    );
};

export default TableOfContentsPanelContainer;
