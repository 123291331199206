import { useRef, useState } from "react";
import styles from "./ForcedSignIn.css";
import { useSelector } from "react-redux";
import { useSelectI18nStringById } from "../../../util/custom-hooks";
import { selectAnnotationsOutOfService, selectLoggedIn } from "../../selectors";
import { useClickBoundary } from "@churchofjesuschrist/eden-click-boundary";
import { Clear } from "@churchofjesuschrist/eden-icons";
import fatIconButtonFactory from "../FloatingAnnotationToolbar/utils/fatIconButtonFactory";
import Callout from "../Callout";
import Divider from "../FloatingAnnotationToolbar/components/Divider";
import SigninPrompt from "../SignInPrompt";
import { H6 } from "../../theming/components/eden-headings";
import OutOfServiceMessage from "../OutOfServiceMessage";

const CloseButton = fatIconButtonFactory(Clear);

export const ForcedSignIn = ({
    annotationsOutOfService,
    arrowDirection,
    children,
    className,
    disabled,
    loggedIn,
    selectI18nStringById,
}) => {
    const [open, setOpen] = useState(false);
    const toggleIconRef = useRef();

    const onClickOutside = (event) => {
        const isToggleIconClicked = toggleIconRef.current.contains(
            event.target
        );

        if (!open || !isToggleIconClicked) {
            setOpen(!open);
        }
    };

    const calloutRef = useClickBoundary({ onClickOutside });

    const handleClick = (event) => {
        if (!disabled) {
            event.stopPropagation();
            setOpen(!open);
        }
    };

    return (
        <>
            {loggedIn && !annotationsOutOfService ? (
                children
            ) : (
                <div style={{ position: "relative" }}>
                    <div onClickCapture={handleClick} ref={toggleIconRef}>
                        {children}
                    </div>
                    <Callout
                        className={className}
                        arrowDirection={arrowDirection}
                        ref={calloutRef}
                        open={open}
                    >
                        <div className={styles.calloutChild}>
                            <header>
                                <H6 renderAs="div">
                                    {selectI18nStringById(
                                        "annotationMenuSignInTitle"
                                    )}
                                </H6>
                                <CloseButton
                                    className={styles.closeButton}
                                    onClick={() => setOpen(false)}
                                />
                            </header>
                            <Divider />
                            {annotationsOutOfService && loggedIn ? (
                                <OutOfServiceMessage />
                            ) : (
                                <SigninPrompt
                                    signInDescription={selectI18nStringById(
                                        "signInPromptDescription"
                                    )}
                                />
                            )}
                        </div>
                    </Callout>
                </div>
            )}
        </>
    );
};

const ForcedSignInContainer = (props) => {
    const annotationsOutOfService = useSelector(selectAnnotationsOutOfService);
    const loggedIn = useSelector(selectLoggedIn);
    const selectI18nStringById = useSelectI18nStringById();

    return (
        <ForcedSignIn
            annotationsOutOfService={annotationsOutOfService}
            loggedIn={loggedIn}
            selectI18nStringById={selectI18nStringById}
            {...props}
        />
    );
};

export default ForcedSignInContainer;
