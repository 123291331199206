import styles from "./DeleteAlert.css";
import { useClickBoundary } from "@churchofjesuschrist/eden-click-boundary";
import { useSelectI18nStringById } from "../../../util/custom-hooks";
import {
    SecondaryButton,
    GhostButton,
} from "../../theming/components/eden-buttons";
import { ErrorAlert, ModalAlert } from "../../theming/components/eden-alert";
import { Text4 } from "../../theming/components/eden-text";
import { Title4 } from "../../theming/components/eden-headings";

const InlineAlert = ({ onClose, children, className }) => {
    const onClickOutside = (event) => {
        event.stopPropagation();
        onClose && onClose();
    };

    const clickRef = useClickBoundary({ onClickOutside });

    return (
        <div className={className} ref={clickRef}>
            {children}
        </div>
    );
};

const DeleteAlert = ({
    className,
    onClose = () => {},
    onDelete = () => {},
    inline = false,
    message,
    selectI18nStringById,
    title,
    testId,
}) => {
    const handleDelete = () => {
        onDelete();
        onClose();
    };

    const ModalOrInline = inline ? InlineAlert : ModalAlert;

    return (
        <ModalOrInline open={true} onClose={onClose} className={className}>
            <ErrorAlert onClose={onClose}>
                <div
                    className={styles.innerAlert}
                    data-testid={`${testId}-delete-modal`}
                >
                    <Title4>{title}</Title4>
                    <Text4>{message}</Text4>
                    <div className={styles.buttons}>
                        <SecondaryButton
                            onClick={handleDelete}
                            small={true}
                            data-testid={`${testId}-delete-modal-submit`}
                        >
                            {selectI18nStringById("deleteAlertDeleteButton")}
                        </SecondaryButton>
                        <GhostButton
                            onClick={onClose}
                            small={true}
                            data-testid={`${testId}-delete-modal-cancel`}
                        >
                            {selectI18nStringById("deleteAlertCancelButton")}
                        </GhostButton>
                    </div>
                </div>
            </ErrorAlert>
        </ModalOrInline>
    );
};

const DeleteAlertContainer = (props) => {
    const selectI18nStringById = useSelectI18nStringById();

    return (
        <DeleteAlert {...props} selectI18nStringById={selectI18nStringById} />
    );
};

export default DeleteAlertContainer;
