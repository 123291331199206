import styles from "./MenuLabel.css";
import classes from "classnames";
import { FormField, Label } from "@churchofjesuschrist/eden-form-parts";
import { Text4, Text5 } from "../../theming/components/eden-text";

export const MenuLabel = ({
    align,
    children,
    className,
    description,
    disabled,
    labelId,
    testId,
    title,
    tooltip,
}) => {
    if (align === undefined) {
        align = description ? "bottom" : "top";
    }

    return (
        <FormField>
            <Label
                className={classes(
                    styles.menuLabel,
                    className,
                    disabled && styles.disabled
                )}
                htmlFor={labelId}
            >
                <div data-testid={`${testId}-menu-label`}>
                    <Text4 emphasized={true}>{title}</Text4>
                    {tooltip}
                    {align === "top" && (
                        <span className={styles.children}>{children}</span>
                    )}
                </div>
                {description && (
                    <div>
                        <Text5>{description}</Text5>
                        {align === "bottom" && (
                            <span className={styles.children}>{children}</span>
                        )}
                    </div>
                )}
            </Label>
        </FormField>
    );
};

export default MenuLabel;
