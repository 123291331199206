import { PureComponent } from "react";
import classes from "classnames";
import styles from "./TableOfContents.css";
import Section from "./components/Section.js";

class TableOfContents extends PureComponent {
    render() {
        const {
            activeUri,
            className = "",
            book: { quickNav, ...book },
        } = this.props;

        return (
            <nav
                className={classes(
                    styles.tableOfContents,
                    quickNav ? styles.hasQuickNav : "",
                    className
                )}
                ref={(self) => (this.self = self)}
            >
                {book.entries && (
                    <Section
                        activeForce={false}
                        activeUri={activeUri}
                        levelProps={{ className: styles.sectionTitle }}
                        sectionClassName={styles.bookTitle}
                        bookUri={book.uri}
                        {...book}
                    />
                )}
            </nav>
        );
    }
}

export default TableOfContents;
