import { createContext } from "react";

export const SelectedPidContext = createContext();

export const ParagraphSelectorProvider = ({ children, value }) => {
    return (
        <SelectedPidContext.Provider value={value}>
            {children}
        </SelectedPidContext.Provider>
    );
};
