import { useLayoutEffect, useRef, useState } from "react";
import styles from "./PositionByRange.css";
import classes from "classnames";
import isMobile from "../../../util/is-mobile";

const TOP_OFFSET = isMobile.apple.device ? 16 : 8;
const BOTTOM_OFFSET = 24;

export const PositionByRange = ({ children, container, range }) => {
    const self = useRef();

    const [renderPosition, setRenderPosition] = useState();

    // needs to be useLayoutEffect and not useEffect
    useLayoutEffect(() => {
        const selfRects = self.current?.getBoundingClientRect() || {};

        const predictedTop = rangeRects.top - selfRects.height - TOP_OFFSET;
        const predictedBottom =
            rangeRects.bottom + selfRects.height + BOTTOM_OFFSET;
        const predictedOffsetTop = predictedTop - containerRects.top;

        const renderOnTop = !(predictedOffsetTop < 20 || predictedTop < 60);
        const renderOnBottom = !(predictedBottom > window.innerHeight - 20);

        setRenderPosition(
            renderOnTop ? "top" : renderOnBottom ? "bottom" : "middle"
        );
        // TODO: Disable below is temporary to have as little functionality change as possible in this PR
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [range]);

    const rangeRects = range?.getBoundingClientRect() || {};
    const containerRects = container?.getBoundingClientRect() || {};

    let positioningStyles = {};

    if (renderPosition === "top") {
        positioningStyles.bottom = `${
            containerRects.bottom - rangeRects.top + TOP_OFFSET
        }px`;
    } else if (renderPosition === "bottom") {
        positioningStyles.top = `${
            rangeRects.bottom - containerRects.top + BOTTOM_OFFSET
        }px`;
    } else {
        // default to rendering in the middle
        positioningStyles.top = `${
            window.innerHeight / 2 - containerRects.top
        }px`;
        positioningStyles.transform = "translate(-50%, -50%)";
    }

    return (
        <div
            onPointerUpCapture={(event) => event.stopPropagation()}
            onMouseUpCapture={(event) => event.stopPropagation()}
            onTouchEndCapture={(event) => event.stopPropagation()}
            onKeyUpCapture={(event) => event.stopPropagation()}
            onContextMenuCapture={(event) => event.stopPropagation()}
            className={classes(
                styles.positionByRange,
                !renderPosition && styles.hidden
            )}
            ref={self}
            style={positioningStyles}
        >
            {children}
        </div>
    );
};

export default PositionByRange;
