import styles from "./eden-platform-modal.css";
import classes from "classnames";
import EdenModal from "@churchofjesuschrist/eden-platform-modal";

export const Modal = ({ className, ...props }) => (
    <EdenModal className={classes(className, styles.modal)} {...props} />
);

export const ModalNoHeader = ({ className, ...props }) => (
    <EdenModal
        className={classes(className, styles.modalNoHeader)}
        {...props}
    />
);
