import { useEffect, useState } from "react";
import styles from "./PrintOptionsModal.css";
import { useSelectI18nStringById } from "../../../util/custom-hooks";
import analytics from "../../../util/analytics";
import ToolModal from "@churchofjesuschrist/eden-tool-modal";
import PrintOptions from "../PrintOptions";

export const PrintOptionsModal = ({ selectI18nStringById }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const closeModal = () => setModalOpen(false);

    useEffect(() => {
        window.addEventListener("keydown", handleKeydown);

        return () => {
            window.removeEventListener("keydown", handleKeydown);
        };
    }, []);

    const handleKeydown = (event) => {
        if (event.keyCode === 80 && (event.ctrlKey || event.metaKey)) {
            event.preventDefault();
            setModalOpen(true);

            analytics.fireComponentClickEvent({
                component: {
                    attributes: { inputDevice: "keyboard" },
                    category: { primary: "modal" },
                    info: { name: "print" },
                },
            });
        }
    };
    if (!modalOpen) {
        // Don't include modal by default, so the related css/js code is not loaded until needed
        return null;
    }

    return (
        <ToolModal
            open={modalOpen}
            className={styles.printModal}
            header={selectI18nStringById("printSettingsTitle")}
            onClose={closeModal}
        >
            <PrintOptions onCancel={closeModal} onClose={closeModal} />
        </ToolModal>
    );
};

const PrintOptionsModalContainer = (props) => {
    const selectI18nStringById = useSelectI18nStringById();

    return (
        <PrintOptionsModal
            {...props}
            selectI18nStringById={selectI18nStringById}
        />
    );
};

export default PrintOptionsModalContainer;
