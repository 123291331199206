export function sortOffsets(a, b) {
    return a.pid - b.pid;
}

export function compareOffsets(offsets = [], offsets2 = []) {
    offsets = offsets.sort(sortOffsets);
    offsets2 = offsets2.sort(sortOffsets);

    return (
        offsets.length === offsets2.length &&
        offsets.every(
            (offset, i) =>
                offset.pid === offsets2[i].pid &&
                parseInt(offset.startOffset, 10) ===
                    parseInt(offsets2[i].startOffset, 10) &&
                parseInt(offset.endOffset, 10) ===
                    parseInt(offsets2[i].endOffset, 10)
        )
    );
}

export function findMatchingOffsets(annotations, newOffsets) {
    return Object.values(annotations).filter(({ highlights }) =>
        compareOffsets(highlights, newOffsets)
    );
}
