/* eslint-disable react/display-name */
import styles from "../FloatingAnnotationToolbar.css";
import classes from "classnames";
import IconButton from "../../IconButton";

const fatIconButtonFactory =
    (Icon) =>
    ({ className, ...props }) =>
        (
            <IconButton
                className={classes(className, styles.fatIconButton)}
                Icon={Icon}
                {...props}
            />
        );

export default fatIconButtonFactory;
