// TODO: The below disabled eslint rules were done quickly to get the code to compile. They should be reviewed and re-enabled or corrected as necessary.
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import styles from "../TableOfContents.css";

const Title = ({
    titleNumber,
    seriesTitle,
    songNumber,
    subtitle,
    title,
    onClick,
}) => (
    <div className={styles.itemTitle} onClick={onClick}>
        {titleNumber && (
            <p
                className={styles.titleNumber}
                dangerouslySetInnerHTML={{ __html: titleNumber }}
            />
        )}
        {seriesTitle && (
            <p
                className={styles.seriesTitle}
                dangerouslySetInnerHTML={{ __html: seriesTitle }}
            />
        )}
        {title && (
            <p className={styles.title}>
                {songNumber && (
                    <span className={styles.songNumber}>{songNumber}</span>
                )}
                <span dangerouslySetInnerHTML={{ __html: title }} />
            </p>
        )}
        {subtitle && (
            <p
                className={styles.subtitle}
                dangerouslySetInnerHTML={{ __html: subtitle }}
            />
        )}
    </div>
);

export default Title;
