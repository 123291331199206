import { forwardRef } from "react";
import classes from "classnames";
import {
    Actionable as EdenActionable,
    Combo as EdenCombo,
    Dismissible as EdenDismissible,
} from "@churchofjesuschrist/eden-tag";

export const Actionable = forwardRef(
    ({ className, selected, ...props }, ref) => (
        <EdenActionable
            aria-selected={selected ? "true" : "false"}
            className={classes(className)}
            {...props}
            ref={ref}
            selected={selected}
        />
    )
);
Actionable.displayName = "Actionable";

export const Combo = forwardRef(({ className, selected, ...props }, ref) => (
    <EdenCombo
        aria-selected={selected ? "true" : "false"}
        className={classes(className)}
        {...props}
        ref={ref}
        selected={selected}
    />
));
Combo.displayName = "Combo";

export const Dismissible = forwardRef(
    ({ className, selected, ...props }, ref) => (
        <EdenDismissible
            aria-selected={selected ? "true" : "false"}
            className={classes(className)}
            {...props}
            ref={ref}
            selected={selected}
        />
    )
);
Dismissible.displayName = "Dismissible";

export {
    Actionable as ActionableTag,
    Combo as ComboTag,
    Dismissible as DismissibleTag,
};
