import { useEffect, useState } from "react";
import fatIconButtonFactory from "../utils/fatIconButtonFactory";
import { Copy as CopyIcon, Done } from "@churchofjesuschrist/eden-icons";

const CopyButton = fatIconButtonFactory(CopyIcon);
const DoneButton = fatIconButtonFactory(Done);

const Copy = ({ disabled, handleClick, title }) => {
    const [clicked, setClicked] = useState(false);

    const handleButtonClick = () => {
        setClicked(true);
        handleClick();
    };

    useEffect(() => {
        if (clicked) {
            const timer = setTimeout(() => {
                setClicked(false);
            }, 1800);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [clicked]);

    return clicked ? (
        <DoneButton
            style={{ color: "green" }}
            title={title}
            data-test-id="fat-copy-button-success"
        />
    ) : (
        <CopyButton
            disabled={disabled}
            onClick={handleButtonClick}
            title={title}
            data-test-id="fat-copy-button"
        />
    );
};

export default Copy;
