import { PureComponent, Fragment } from "react";
import classes from "classnames";
import styles from "../TableOfContents.css";
import handleClickAndEnterKey from "../../../../util/handle-click-and-enter-key";
import analytics from "../../../../util/analytics";

import {
    ChevronDown as ClosedIcon,
    ChevronUp as OpenIcon,
} from "@churchofjesuschrist/eden-icons";
import SmartLink from "../../SmartLink";
import Title from "./Title.js";
import Item from "./Item.js";
import { spacing24 } from "@churchofjesuschrist/eden-style-constants";

class Section extends PureComponent {
    static defaultProps = {
        childUris: [],
        directChildUris: [],
    };

    state = {
        isAnyChildActive: false,
        open: false,
        userOpenState: undefined,
    };

    static getDerivedStateFromProps(props, state) {
        let { activeUri, childUris } = props;
        let { open, userOpenState } = state;

        let isAnyChildActive = activeUri && childUris.includes(activeUri);

        if (!props.type?.includes("drawer")) {
            return { isAnyChildActive };
        }

        // userOpenState determines if the drawer is open
        if (userOpenState !== undefined) {
            open = userOpenState;
            // if userOpenState is undefined then isAnyChildActive determines if the drawer is open
        } else {
            open = isAnyChildActive;
        }

        // if isAnyChildActive is false, then userOpenState must be true or undefined
        userOpenState =
            isAnyChildActive || userOpenState ? userOpenState : undefined;

        return {
            isAnyChildActive,
            open,
            userOpenState,
        };
    }

    buildKey = ({ uri = "", title }) => uri + title;

    openDrawer = () => {
        this.setState({ userOpenState: !this.state.open });
    };

    handleAnalytics = () => {
        analytics.fireComponentClickEvent({
            component: {
                info: { name: "table of contents|link" },
                category: { primary: "table of contents" },
            },
        });
    };

    render() {
        const {
            activeForce,
            activeUri,
            bookUri,
            children,
            directChildUris,
            entries,
            id,
            indent = 0,
            levelProps = {},
            sectionClassName,
            seriesTitle,
            subtitle,
            title,
            titleNumber,
            type,
            uri,
        } = this.props;

        const { isAnyChildActive, open } = this.state;
        let active;
        let hasActiveDirectChild;

        if (activeUri) {
            active =
                activeForce == null
                    ? activeUri === (uri && type !== "content-drawer") ||
                      (isAnyChildActive && type?.includes("drawer") && !open)
                    : activeForce;
            hasActiveDirectChild =
                type?.includes("drawer") && directChildUris.includes(activeUri);
        }

        let nextLevelProps = {
                // keep nested sections/items from needless rerendering when an activeUri change won't effect them.
                activeUri: (isAnyChildActive || uri === bookUri) && activeUri,
                bookUri,
                indent: indent + 1,
                sectionClassName: styles.sectionTitle,
                ...levelProps,
            },
            sectionTitleClass = classes(
                sectionClassName,
                (active || hasActiveDirectChild) && styles.active,
                type === "label"
                    ? styles.sectionLabel
                    : type?.includes("drawer") && styles.sectionDrawer
            ),
            subItemClass = classes(
                styles.subItems,
                hasActiveDirectChild && styles.active,
                (!type?.includes("drawer") || open) && styles.open
            );

        const elementId =
            id ||
            (type?.includes("label") && "li" + String(title).toLowerCase()) ||
            null;

        return (
            <Fragment>
                {title && (
                    <SmartLink
                        to={
                            type?.includes("content") &&
                            !open &&
                            !hasActiveDirectChild
                                ? uri
                                : undefined
                        }
                        className={sectionTitleClass}
                        onClick={handleClickAndEnterKey(this.openDrawer)}
                        onKeyPress={handleClickAndEnterKey(this.openDrawer)}
                        style={{ paddingInlineStart: `${indent * 16}px` }}
                        tabIndex={type && type !== "label" ? 0 : undefined}
                    >
                        <Title
                            active={active ? active : undefined}
                            title={title}
                            subtitle={subtitle}
                            seriesTitle={seriesTitle}
                            titleNumber={titleNumber}
                        />
                        {type?.includes("drawer") &&
                            (open ? (
                                <OpenIcon size={spacing24} />
                            ) : (
                                <ClosedIcon size={spacing24} />
                            ))}
                    </SmartLink>
                )}
                {children}
                {(!type?.includes("drawer") || open) && (
                    <ul id={elementId} className={subItemClass}>
                        {entries.map(({ section, content }) => (
                            <li key={`li_${this.buildKey(section || content)}`}>
                                {section ? (
                                    <Section {...nextLevelProps} {...section} />
                                ) : (
                                    <Item
                                        {...nextLevelProps}
                                        {...content}
                                        onClick={this.handleAnalytics}
                                    />
                                )}
                            </li>
                        ))}
                    </ul>
                )}
            </Fragment>
        );
    }
}

export default Section;
