import styles from "./Tags.css";
import {
    ActionableTag,
    ComboTag,
    DismissibleTag,
} from "../../theming/components/eden-tag";
import SmartLink from "../SmartLink";
import classes from "classnames";

const Actionable = ({ handleClick, href, ...props }) => {
    const tag = <ActionableTag onClick={handleClick} {...props} />;

    return href ? <SmartLink href={href}>{tag}</SmartLink> : tag;
};

const Combo = ({
    children,
    disabled,
    handleClick,
    // eslint-disable-next-line no-unused-vars -- define so it isn't included in ...props
    href,
    onClose,
    selected,
    ...props
}) => (
    <ComboTag {...props}>
        <ActionableTag
            disabled={disabled}
            onClick={handleClick}
            selected={selected}
        >
            {children}
        </ActionableTag>
        <Dismissible disabled={disabled} onClick={onClose} selected={selected}>
            {children}
        </Dismissible>
    </ComboTag>
);

const Dismissible = ({ onClose, ...props }) => (
    <DismissibleTag onClick={onClose} {...props} />
);

const COMPONENT_LOOK_UP = {
    actionable: Actionable,
    combo: Combo,
    dismissible: Dismissible,
};

const Tags = ({
    className,
    editing,
    onClose,
    handleClick,
    tags = [],
    type = "actionable",
}) => {
    const TagComponent = COMPONENT_LOOK_UP[type];

    return tags.length ? (
        <section className={className}>
            <div className={styles.innerWrapper}>
                {tags.map(
                    ({ content, disabled, href, key, name, selected }, i) => {
                        selected = selected ?? (editing && editing === key);

                        return (
                            <TagComponent
                                className={classes(styles.tag, selected)}
                                disabled={disabled}
                                handleClick={(e) => handleClick(e, i, href)}
                                href={href}
                                key={`tag-${key || name || i}`}
                                onClose={(e) => onClose(e, i)}
                                selected={selected}
                            >
                                {content || name}
                            </TagComponent>
                        );
                    }
                )}
            </div>
        </section>
    ) : null;
};

export default Tags;
