import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { addTag, removeTag } from "../../actions/annotations";
import { selectActiveHighlightAnnotation, selectTags } from "../../selectors";
import { useSelectI18nStringById } from "../../../util/custom-hooks";
import { Tag as TagIcon } from "@churchofjesuschrist/eden-icons";
import TypeaheadTagListModal from "../TypeaheadTagListModal";

export const TagModal = ({
    addTag: addTagAction,
    annotation,
    onClose,
    removeTag,
    selectI18nStringById,
    tags = {},
    ...props
}) => {
    const items = useMemo(
        () =>
            Object.values(tags)
                .filter((tag) => !annotation?.tags.includes(tag))
                .map(({ name, id }) => ({ title: name, id })),
        [annotation, tags]
    );
    const addTag = ({ title, id } = {}) => addTagAction({ name: title, id });

    // If there is no annotation id toggle/close the modal
    useEffect(() => {
        !annotation && onClose();
    }, [annotation, onClose]);

    return (
        <TypeaheadTagListModal
            addNewString={selectI18nStringById("annotationNewTagPostTitle")}
            icon={<TagIcon size="1.5rem" />}
            renderId={annotation?.id}
            items={items}
            onClose={() => onClose()}
            onSubmit={addTag}
            placeholderText={selectI18nStringById("addTagPlaceholder")}
            removeTag={removeTag}
            tagItems={annotation?.tags}
            title={selectI18nStringById("annotationTags")}
            testId="tag"
            {...props}
        />
    );
};

const TagModalContainer = (props) => {
    const dispatch = useDispatch();
    const activeAnnotation = useSelector(selectActiveHighlightAnnotation);
    const selectI18nStringById = useSelectI18nStringById();
    const tags = useSelector(selectTags);
    const actions = useMemo(
        () =>
            bindActionCreators(
                {
                    addTag,
                    removeTag,
                },
                dispatch
            ),
        [dispatch]
    );

    return (
        <TagModal
            annotation={activeAnnotation}
            selectI18nStringById={selectI18nStringById}
            tags={tags}
            {...actions}
            {...props}
        />
    );
};

export default TagModalContainer;
