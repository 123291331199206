import styled from "styled-components";
import Typeahead from "@churchofjesuschrist/glo-typeahead";
import {
    fontSize16,
    grey20,
    sans,
    spacing8,
} from "@churchofjesuschrist/eden-style-constants";

const Container = styled.div`
    align-items: top;
    display: grid;
    grid-template-columns: min-content auto;
    flex-wrap: wrap;
    position: relative;
`;

const Form = styled.div`
    margin-block-end: ${spacing8};
    align-self: stretch;
    width: 100%;

    & input {
        min-width: 100px;
        border: none;
        outline: none;
        font-family: ${sans};
        font-size: ${fontSize16};

        &::placeholder {
            color: var(--text-secondary, ${grey20});
        }
    }
`;

const SearchAndTagsWrapper = styled.div`
    width: 100%;
    align-items: center;
`;

const TypeaheadTagList = ({
    autoFocus,
    children,
    className,
    items,
    minChars,
    renderId,
    onSubmit,
    placeholder,
    render,
    renderProps,
    resultsModifier,
    searchKeys,
}) => (
    <Container className={className}>
        {children}
        <SearchAndTagsWrapper>
            <Form>
                <Typeahead
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus={autoFocus}
                    contain={false}
                    items={items}
                    renderId={renderId}
                    minChars={minChars}
                    onSubmit={onSubmit}
                    placeholder={placeholder}
                    resultsModifier={resultsModifier}
                    searchKeys={searchKeys}
                />
            </Form>
            {render(renderProps)}
        </SearchAndTagsWrapper>
    </Container>
);

export default TypeaheadTagList;
