/* eslint-disable react/display-name */
import { memo, useEffect, useMemo } from "react";
import styles from "./RecordImpressionsButton.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
    addHighlight,
    changeActiveAnnotation,
} from "../../actions/annotations";
import { showToast } from "../../actions/notifications";
import { addFloatingPanel } from "../../actions/sidePanel";
import {
    selectAnnotationsInitialized,
    selectAnnotationsOutOfService,
    selectHighlightAnnotationsByDocId,
    selectLoggedIn,
} from "../../selectors";
import { buildSignInUrl } from "../SignInPrompt";
import { useSelectI18nStringById } from "../../../util/custom-hooks";
import { findMatchingOffsets } from "../../../util/offset-utils";
import {
    addToLocalStorage,
    deleteFromLocalStorage,
    getFromLocalStorage,
} from "../../../util/store-on-client.js";
import { toContentUri } from "../../../util/uri-utils";
import { PrimaryButton } from "../../theming/components/eden-buttons";

function isMatchingOffset(offsets, pid) {
    return offsets.length === 1 && offsets[0].pid === pid;
}

const recordImpressions = async ({
    addHighlight,
    changeActiveAnnotation,
    loggedIn,
    matchingAnnotations = [],
    offsets,
    addFloatingPanel,
    location,
}) => {
    if (!loggedIn) {
        addToLocalStorage("highlightRange", offsets);

        window.location = buildSignInUrl();

        return;
    }

    if (!matchingAnnotations.length) {
        let annotationId = await addHighlight(
            { offsets, style: { clear: true } },
            location,
            false
        );
        matchingAnnotations = [{ id: annotationId }];
    }

    const annotation = matchingAnnotations[0];
    changeActiveAnnotation(annotation.id);

    addFloatingPanel({
        annotationId: annotation.id,
        autoFocus: "notes",
        id: `${annotation.id}-editor`,
        type: "editor",
    });
};

const RecordImpressionsButton = memo((props) => {
    const {
        annotations,
        annotationsInitialized,
        annotationsOutOfService,
        location,
        loggedIn,
        pid,
        selectI18nStringById,
        showToast,
        siblingId,
    } = props;

    const offsets = [
        {
            endOffset: "-1",
            startOffset: "-1",
            uri: `${toContentUri(location.pathname)}.${siblingId}`,
            pid,
        },
    ];

    const matchingAnnotations = findMatchingOffsets(annotations, offsets);

    useEffect(() => {
        const storedOffsets = JSON.parse(getFromLocalStorage("highlightRange"));
        const shouldCheckOffsets =
            storedOffsets && loggedIn && annotationsInitialized;

        if (shouldCheckOffsets && isMatchingOffset(storedOffsets, pid)) {
            deleteFromLocalStorage("highlightRange");
            recordImpressions({
                ...props,
                location,
                matchingAnnotations,
                offsets,
            });
        }
        // TODO: Disable below is temporary to have as little functionality change as possible in this PR
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn, annotationsInitialized, pid]);

    const handleOnClick = () => {
        annotationsOutOfService
            ? showToast({
                  type: "annotations-out-of-service",
                  disableAutoHide: true,
              })
            : recordImpressions({
                  ...props,
                  location,
                  matchingAnnotations,
                  offsets,
              });
    };

    const annotationNote = matchingAnnotations && matchingAnnotations[0]?.note;
    const hasNote =
        annotationNote &&
        (annotationNote?.content?.length || annotationNote?.title?.length);

    const text = hasNote
        ? selectI18nStringById("recordImpressionsButtonTextEdit")
        : selectI18nStringById("recordImpressionsButtonTextCreate");

    return (
        <PrimaryButton className={styles.button} onClick={handleOnClick}>
            {text}
        </PrimaryButton>
    );
});

const RecordImpressionsButtonContainer = (props) => {
    const location = useLocation();
    const annotations = useSelector((state) =>
        selectHighlightAnnotationsByDocId(state, location)
    );
    const annotationsInitialized = useSelector((state) =>
        selectAnnotationsInitialized(state, location)
    );
    const annotationsOutOfService = useSelector(selectAnnotationsOutOfService);
    const loggedIn = useSelector(selectLoggedIn);
    const selectI18nStringById = useSelectI18nStringById();

    const dispatch = useDispatch();
    const actions = useMemo(
        () =>
            bindActionCreators(
                {
                    addHighlight,
                    addFloatingPanel,
                    changeActiveAnnotation,
                    showToast,
                },
                dispatch
            ),
        [dispatch]
    );

    return (
        <RecordImpressionsButton
            annotations={annotations}
            annotationsInitialized={annotationsInitialized}
            annotationsOutOfService={annotationsOutOfService}
            location={location}
            loggedIn={loggedIn}
            selectI18nStringById={selectI18nStringById}
            {...actions}
            {...props}
        />
    );
};

RecordImpressionsButtonContainer.displayName =
    "RecordImpressionsButtonContainer";

export default RecordImpressionsButtonContainer;
