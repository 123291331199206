import { useEffect, useMemo, useRef, useState } from "react";
import styles from "./FloatingAnnotationToolbar.css";
import classes from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { changeActiveAnnotation } from "../../actions/annotations";
import {
    calcDuration,
    calcEndPosition,
    calcInitialOffset,
    timing,
} from "./utils/animation";
import {
    selectActiveHighlightAnnotation,
    selectToolbarClosed,
} from "../../selectors";
import {
    useMountEffect,
    useSelectBookmarksByPid,
} from "../../../util/custom-hooks";
import DefaultToolbar from "./components/DefaultToolbar";
import HighlightMenu from "./components/HighlightMenu";
import Closed from "./components/Closed";
import Share from "./components/Share";
import { getTopPid } from "../../../util/utils";
import { updateBookmark } from "../../services/annotations";

const DEFAULT_POSITION = 48;
const BOTTOM_BUFFER_DESKTOP = 16;
const BOTTOM_BUFFER_MOBILE = 60;
const KEYCODE_TAB = 9;

const viewLookup = {
    DefaultToolbar,
    HighlightMenu,
    Closed,
    Share,
};

export const FloatingAnnotationToolbar = ({
    activeAnnotation,
    activeSelection,
    activeUri,
    clearSelection,
    offsets,
    openCrossLinkOverlay,
    openNotebookModal,
    openTagModal,
    range,
    selectBookmarksByPid,
    toolbarClosed,
    ...props
}) => {
    const [sticky, setSticky] = useState(false);
    const [toolbarState, setToolbarState] = useState(
        toolbarClosed ? "Closed" : "DefaultToolbar"
    );

    const Toolbar =
        (toolbarState !== undefined && viewLookup[toolbarState]) ||
        (() => (console.error(`${toolbarState} is not a valid view`), null));

    const animationId = useRef(null);
    const lastActiveUri = useRef(activeUri);
    const mediaQueryListRef = useRef();
    const toolbarMarkRef = useRef();
    const toolbarRef = useRef();
    const toolbarTrackRef = useRef();

    const isClosed = toolbarState === "Closed";

    useEffect(() => {
        if (lastActiveUri.current !== activeUri) {
            lastActiveUri.current = activeUri;
        }

        const initialOffset = calcInitialOffset(selectBookmarksByPid);
        toolbarTrackRef?.current?.style.setProperty(
            "--initialOffset",
            `${initialOffset}px`
        );
    }, [activeUri, selectBookmarksByPid, activeSelection]);

    useEffect(() => {
        // This block of code should help catch and repair any bookmarks that were created without a PID
        selectBookmarksByPid(undefined)
            ?.filter(
                // Get all bookmarks without a PID
                (bookmark) => bookmark.uri === activeUri.replace(/^\/study/, "") // See if any are on the current page
            )
            ?.forEach((bookmark) => {
                // For all the ones that are...
                const topPid = getTopPid({ fullpage: true })?.dataset?.aid; // Get the first PID on this page
                updateBookmark(bookmark.annotationId, { topPid }); // Update the bookmark with the first PID on this page
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectBookmarksByPid]);

    useMountEffect(() => {
        toolbarTrackRef?.current?.style.setProperty("--toolbar-top", "0px");
        mediaQueryListRef.current = window.matchMedia("(max-width: 46em)");
    });

    useMountEffect(() => {
        const handleKeyDownCloseSelection = (event) => {
            setTimeout(() => {
                const isTabPressed =
                    event.key === "Tab" || event.keyCode === KEYCODE_TAB;

                if (
                    isTabPressed &&
                    !toolbarRef.current.contains(document.activeElement)
                ) {
                    clearSelection();
                }
            }, 0);
        };

        document.body.addEventListener("keydown", handleKeyDownCloseSelection);

        return () => {
            document.body.removeEventListener(
                "keydown",
                handleKeyDownCloseSelection
            );
        };
    });

    useMountEffect(() => {
        const handleResizeObserver = (entries) => {
            entries.forEach((entry) => {
                if (entry.target === toolbarRef.current) {
                    const { blockSize, inlineSize } = entry.borderBoxSize[0];

                    toolbarMarkRef.current.style.setProperty(
                        "height",
                        `${blockSize}px`
                    );
                    toolbarMarkRef.current.style.setProperty(
                        "width",
                        `${inlineSize}px`
                    );
                }
            });
        };

        const resizeObserver = new ResizeObserver(handleResizeObserver);

        resizeObserver.observe(toolbarRef.current);

        return () => resizeObserver.disconnect();
    });

    useEffect(() => {
        const handleIntersection = (entries) => {
            entries.forEach(
                ({
                    boundingClientRect,
                    isIntersecting,
                    rootBounds,
                    target,
                }) => {
                    if (target === toolbarMarkRef.current) {
                        const atBottom =
                            boundingClientRect.bottom >= rootBounds.bottom;

                        const stickyTop = atBottom
                            ? rootBounds.bottom - boundingClientRect.height
                            : rootBounds.top;

                        toolbarRef.current.style.setProperty(
                            "--toolbar-sticky-top",
                            `${stickyTop}px`
                        );

                        // set the class via react and the dom so that the class
                        // is adjusted quicker and persists when react updates
                        toolbarRef.current.classList.toggle(
                            styles.sticky,
                            !isIntersecting
                        );
                        setSticky(!isIntersecting);
                    }
                }
            );
        };

        const bottomBuffer = mediaQueryListRef.current?.matches
            ? BOTTOM_BUFFER_MOBILE
            : BOTTOM_BUFFER_DESKTOP;

        const intersectionObserver = new IntersectionObserver(
            handleIntersection,
            {
                rootMargin: `-${DEFAULT_POSITION}px 0px -${bottomBuffer}px 0px`,
                threshold: 1.0,
            }
        );

        intersectionObserver.observe(toolbarMarkRef.current);

        return () => intersectionObserver.disconnect();
    }, [mediaQueryListRef.current?.matches, toolbarState]);

    // This useEffect handles the animated movement of the FAT. Here's a quick desc how it works:
    //   --toolbar-top is where the FAT should sit, so next to the activeSelection or at the top (0).
    //   The animate function runs multiple times in a row updating --toolbar-top each time
    //     until it reaches it's final destination.
    useEffect(() => {
        const startAnimation = () => {
            let clientOffset =
                toolbarTrackRef?.current?.getBoundingClientRect().top;
            let startPosition = toolbarRef.current.getBoundingClientRect().top;
            let endPosition = calcEndPosition(range, selectBookmarksByPid);

            let distance = endPosition - startPosition;
            let duration = calcDuration(distance);

            let startTime = performance.now();

            const animate = (currentTime) => {
                let timeFraction = Math.min(
                    (currentTime - startTime) / duration,
                    1
                );
                let offset = timing(timeFraction) * distance;
                let toolbarTop = Math.max(
                    startPosition + offset - clientOffset,
                    0
                );

                toolbarTrackRef?.current?.style.setProperty(
                    "--toolbar-top",
                    `${toolbarTop}px`
                );

                if (timeFraction < 1) {
                    animationId.current = requestAnimationFrame(animate);
                } else if (!activeSelection) {
                    // The animation will return the FAT to the top of the current view - then we
                    // set it back to the top of the toolbar track (0) and the IntersectionObserver
                    // keeps it in place at the top of the current view.
                    toolbarTrackRef?.current?.style.setProperty(
                        "--toolbar-top",
                        "0px"
                    );
                }
            };

            cancelAnimationFrame(animationId.current);

            animationId.current = requestAnimationFrame(animate);
        };

        startAnimation();
    }, [activeSelection, range, selectBookmarksByPid]);

    useEffect(() => {
        // reset to closed if user has the toolbar originally closed
        if (toolbarClosed && !activeAnnotation && !activeSelection) {
            setToolbarState("Closed");
            return;
        }

        ((activeAnnotation && !activeSelection && isClosed) ||
            (activeSelection && isClosed) ||
            ((!activeSelection || !activeAnnotation) &&
                toolbarState === "HighlightMenu")) &&
            setToolbarState("DefaultToolbar");
        // we don't want to run this every time the toolbarState changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        activeAnnotation,
        activeSelection,
        isClosed,
        setToolbarState,
        toolbarClosed,
    ]);

    return (
        <div className={styles.toolbarTrack} ref={toolbarTrackRef}>
            <div className={styles.toolbarMark} ref={toolbarMarkRef} />
            <div
                className={classes(
                    styles.floatingAnnotationToolbar,
                    isClosed && styles.singleItem,
                    sticky && styles.sticky
                )}
                id="floatingAnnotationToolbar"
                ref={toolbarRef}
                tabIndex="-1"
            >
                <Toolbar
                    changeView={setToolbarState}
                    clearSelection={clearSelection}
                    offsets={offsets}
                    openCrossLinkOverlay={openCrossLinkOverlay}
                    openNotebookModal={openNotebookModal}
                    openTagModal={openTagModal}
                    range={range}
                    {...props}
                />
            </div>
        </div>
    );
};

const FloatingAnnotationToolbarContainer = (props) => {
    const dispatch = useDispatch();
    const activeAnnotation = useSelector(selectActiveHighlightAnnotation);
    const toolbarClosed = useSelector(selectToolbarClosed);
    const selectBookmarksByPid = useSelectBookmarksByPid();
    const actions = useMemo(
        () =>
            bindActionCreators(
                {
                    changeActiveAnnotation,
                },
                dispatch
            ),
        [dispatch]
    );

    return (
        <FloatingAnnotationToolbar
            toolbarClosed={toolbarClosed}
            activeAnnotation={activeAnnotation}
            selectBookmarksByPid={selectBookmarksByPid}
            {...actions}
            {...props}
        />
    );
};

export default FloatingAnnotationToolbarContainer;
