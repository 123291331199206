import { useCallback, useMemo } from "react";
import styles from "./TypeaheadTagListModal.css";
import { useSelectI18nStringById } from "../../../util/custom-hooks";
import { iconButtonFactory } from "../IconButton/IconButton";
import Card from "@churchofjesuschrist/eden-card";
import { Clear } from "@churchofjesuschrist/eden-icons";
import TypeaheadTagList from "@churchofjesuschrist/glo-typeahead-tag-list";
import PositionByRange from "../PostionByRange/PositionByRange";
import { GhostButton } from "../../theming/components/eden-buttons/eden-buttons";
import { DismissibleTag } from "../../theming/components/eden-tag/eden-tag";
import { Title4 } from "../../theming/components/eden-headings/eden-headings";

const CloseButton = iconButtonFactory(Clear);

CloseButton.displayName = "CloseButton";

const renderTagList = ({ tagItems, removeTag }) => {
    return tagItems?.map((tagItem) => (
        <DismissibleTag
            className={styles.tag}
            key={tagItem.id}
            showClose
            onClick={() => removeTag(tagItem.id)}
        >
            <span className={styles.tagText}>{tagItem.name}</span>
        </DismissibleTag>
    ));
};

export const TypeaheadTagListModal = ({
    addNewString,
    icon,
    items = [],
    renderId,
    onClose,
    onSave,
    onSubmit,
    placeholderText,
    removeTag,
    searchKeys,
    selectI18nStringById,
    tagItems,
    title,
    testId,
}) => {
    const addNewModifier = useCallback(
        (items, results, value) => {
            const tagNames = tagItems?.map(({ name }) => name);

            return !items.some(
                (item) => item.title === value || tagNames.includes(value)
            )
                ? [
                      {
                          title: value,
                          postTitle: `- ${addNewString}`,
                      },
                      ...results,
                  ]
                : results;
        },
        [addNewString, tagItems]
    );

    const renderProps = useMemo(
        () => ({ removeTag, tagItems }),
        [removeTag, tagItems]
    );

    return (
        <Card depth="poppedOut">
            <div className={styles.typeaheadTagListModal}>
                <header>
                    <Title4
                        className={styles.modalTitle}
                        data-testid={`${testId}-modal-title`}
                    >
                        {title}
                    </Title4>
                    <span className={styles.modalButtons}>
                        <GhostButton
                            onClick={() => onSave()}
                            id="save-button"
                            data-testid={`${testId}-modal-save`}
                        >
                            {selectI18nStringById("annotationMenuSaveText")}
                        </GhostButton>
                        <CloseButton
                            className={styles.closeButton}
                            onClick={() => onClose()}
                            title={selectI18nStringById(
                                "annotationMenuCloseText"
                            )}
                            data-testid={`${testId}-modal-close`}
                        />
                    </span>
                </header>
                <TypeaheadTagList
                    className={styles.tagList}
                    items={items}
                    minChars={1}
                    renderId={renderId}
                    onSubmit={onSubmit}
                    placeholder={placeholderText}
                    render={renderTagList}
                    renderProps={renderProps}
                    resultsModifier={addNewModifier}
                    searchKeys={searchKeys}
                >
                    {icon}
                </TypeaheadTagList>
            </div>
        </Card>
    );
};

export const PositionedTypeaheadTagListModal = ({
    range,
    container,
    ...props
}) => (
    <PositionByRange container={container} range={range}>
        <TypeaheadTagListModal {...props} />
    </PositionByRange>
);

const TypeaheadTagListModalContainer = (props) => {
    const selectI18nStringById = useSelectI18nStringById();

    return (
        <PositionedTypeaheadTagListModal
            selectI18nStringById={selectI18nStringById}
            {...props}
        />
    );
};

export default TypeaheadTagListModalContainer;
