import { useEffect } from "react";
import styleContainer from "../../containers/styles";

// The purpose of this was to hide content until the CSS was loaded and potentially unloaded. This was an artifact from back in the day when the CSS for different sections overwrote each other. With the scoping of scriptures vs lesson that was added this is no longer really an issue. With this codebase on the way out it is easier to just short circuit this to always show the content than to go find all the places that are using this and update them. The `addStyleSheet` still helps with dynamically loaded content for the sidebar and such, so that part is still useful.
const StyleDependentContent = ({
    addStyleSheet,
    children = null,
    currentLinks = [],
    links = [],
}) => {
    useEffect(() => {
        links.forEach((link) => {
            if (
                !currentLinks.find(
                    (existingLink) => existingLink.href === link.href
                )
            ) {
                addStyleSheet(link);
            }
        });
    }, [currentLinks, links, addStyleSheet]);

    return children;
};

export default styleContainer(StyleDependentContent);
