import { forwardRef } from "react";
import styles from "./Callout.css";
import classes from "classnames";
import Card from "@churchofjesuschrist/eden-card";

const Arrow = ({ arrowDirection }) => (
    <div
        className={classes(
            styles.arrow,
            styles[arrowDirection] || styles.blockStart
        )}
    />
);

export const Callout = forwardRef(
    (
        { arrowDirection = "blockStart", className, open = false, children },
        ref
    ) => {
        return open ? (
            <div className={className} ref={ref}>
                <Card
                    className={classes(
                        styles.callout,
                        styles[arrowDirection] || styles.blockStart,
                        className
                    )}
                >
                    <Arrow arrowDirection={arrowDirection} />
                    {children}
                </Card>
            </div>
        ) : null;
    }
);
Callout.displayName = "Callout";

export default Callout;
