export const checkForMatchingRanges = (
    newRange = new Range(),
    activeRange = new Range()
) => {
    return !(
        newRange.compareBoundaryPoints(Range.START_TO_START, activeRange) ||
        newRange.compareBoundaryPoints(Range.END_TO_END, activeRange)
    );
};

// Different: both range endpoints don't match meaning it is a new range and not a dragged one.
export const checkForDifferentRanges = (
    newRange = new Range(),
    activeRange = new Range()
) => {
    const compareValue =
        Math.abs(
            newRange.compareBoundaryPoints(Range.START_TO_START, activeRange)
        ) +
        Math.abs(newRange.compareBoundaryPoints(Range.END_TO_END, activeRange));

    return compareValue === 2;
};
