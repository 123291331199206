import { Component } from "react";
import classes from "classnames";
import styles from "./LDSSourceRenderer.css";
import { attributesToProps } from "html-react-parser";
import mem from "mem";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
    selectActiveAnnotationId,
    selectFontSansSerif,
    selectFontSize,
    selectHighlightsByDocId,
    selectNoIndexStatus,
} from "../../selectors";
import {
    useSelectBookmarksByPid,
    useSelectI18nStringById,
} from "../../../util/custom-hooks";
import { searchToParams } from "../../../util/uri-utils";
import { handleSearch } from "../../../util/handle-search";
import {
    contentsTransform,
    crossLinkTransform,
    highlightTransform,
} from "./transforms";
import Highlight from "@churchofjesuschrist/glo-highlight";
import Frame from "../Frame";
import ReaderHelmet from "../ReaderHelmet";
import Selectable from "../Selectable";
import { WarnAlert } from "../../theming/components/eden-alert/eden-alert";
import { H4 } from "../../theming/components/eden-headings";
import { Text4 } from "../../theming/components/eden-text/eden-text";

const ROOT_MARGIN_TOP_OFFSET = 80;

let options = {
    rootMargin: `-${ROOT_MARGIN_TOP_OFFSET}px 0px 0px 0px` /* TBD want to check what is in the top line  */,
    threshold: 0.01,
};

const PANEL_HEADER_OFFSET = 50;

class LDSSourceRenderer extends Component {
    maxWidthOverridePathnames = [
        "/manual/preach-my-gospel-a-guide-to-missionary-service/",
        "/manual/the-family-a-proclamation-to-the-world/",
        "/manual/the-living-christ-the-testimony-of-the-apostles/",
        "/scriptures/af-poster/",
        "/scriptures/harmony/",
        "/manual/book-of-mormon-stories/",
        "/manual/doctrine-and-covenants-stories/",
        "/manual/new-testament-stories/",
        "/manual/old-testament-stories/",
    ];

    state = {
        isClient: false,
    };

    componentDidMount() {
        this.setState({ isClient: true });

        this.syncedScrollObserver = new IntersectionObserver(
            this.syncedScrollCallback,
            options
        );

        // If there is a searchSnippet cookie we want to alter the content to
        //   contain the highlighted search result
        this.altContentBody = handleSearch(this.props.data);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.uri !== this.props.data.uri) {
            this.altContentBody = handleSearch(this.props.data);
        }
    }

    componentWillUnmount() {
        this.syncedScrollObserver.disconnect();
    }

    syncedScrollCallback = (entries) => {
        let firstIntersectingEntry = entries.filter((entry) => {
            return (
                entry.intersectionRect.top === ROOT_MARGIN_TOP_OFFSET ||
                entry.boundingClientRect.bottom <= ROOT_MARGIN_TOP_OFFSET
            );
        })[0];

        if (firstIntersectingEntry && this.props.defaultPanelRef.current) {
            let rcaItemId = `#rca-${firstIntersectingEntry.target.dataset.scrollId}`;
            let rcaItem = document.querySelector(rcaItemId);

            let rcaItemRect = rcaItem.getBoundingClientRect();
            let rcaItemPosition =
                rcaItemRect.top +
                this.props.defaultPanelRef.current.scrollTop -
                PANEL_HEADER_OFFSET;

            this.props.defaultPanelRef.current.scrollTo({
                top: rcaItemPosition,
                behavior: "smooth",
            });
        }
    };

    getActiveItems = mem((location) => {
        let contentIds = Array.from(
            document.querySelectorAll(
                "[data-aid-version] [id][data-aid], article[data-aid-version] section[id]"
            ),
            (element) => element.id
        );

        let query = searchToParams(location.search);

        let activeItemsStr = [
            (location.pathname.match(/\.(.*$)/) || [])[1] || "",
            query.id,
            query.context,
            query.para,
            query.verse,
        ].join(",");

        let activeItemRanges = activeItemsStr
            .replace(/(\b\d)/g, "p$1")
            .split(",");

        return activeItemRanges.reduce((activeIds, range) => {
            if (!range || /\?/.test(range)) return activeIds;

            let [firstId, lastId = firstId] = range.split("-"),
                firstIndex = contentIds.indexOf(firstId),
                lastIndex = contentIds.indexOf(lastId),
                newElements = contentIds.slice(firstIndex, lastIndex + 1);

            return [
                ...activeIds,
                ...newElements.filter(
                    (element) => !activeIds.includes(element)
                ),
            ];
        }, []);
    });

    comparePathname = (pathname) =>
        this.props.location.pathname.includes(pathname);

    render() {
        const {
            anAnnotationEditorOpen,
            children,
            className,
            crossLinkMode,
            data,
            frameRef,
            fontSansSerif,
            fontSize,
            highlights,
            id,
            noIndex,
            onDragStart,
            onSelectEnd,
            onSelectClear,
            range,
            selectI18nStringById,
            ...styleFrameProps
        } = this.props;

        let styleScope = data.meta.scopedClassName;
        let hasMaxWidth =
            this.maxWidthOverridePathnames.some(this.comparePathname) &&
            "has-max-width";
        let highlightProps;
        const isArchived = data.meta.archived;

        if (/_contents|_manifest/.test(data.meta.pageAttributes["data-uri"])) {
            highlightProps = {
                parseOptions: { replace: contentsTransform() },
                highlights: undefined,
            };
        } else if (crossLinkMode) {
            highlightProps = {
                parseOptions: { replace: crossLinkTransform() },
                highlights: undefined,
            };
        } else {
            highlightProps = {
                parseOptions: {
                    replace: highlightTransform(this.props, this.state, {
                        getActiveItems: this.getActiveItems,
                        syncedScrollObserver: this.syncedScrollObserver,
                    }),
                },
                highlights,
            };
        }

        return (
            <Frame
                type="narrowReader"
                id={id}
                className={classes(className, styles.renderFrame)}
                frameRef={frameRef}
            >
                <ReaderHelmet
                    {...styleFrameProps}
                    meta={data.meta}
                    noIndex={noIndex}
                    pageMeta={data.content.head["page-meta-social"].pageMeta}
                    links={data.content.head.links}
                    scripts={data.content.head.scripts}
                    title={data.content.head.title}
                />

                {isArchived && (
                    <WarnAlert
                        className={styles.archivedAlert}
                        id="archivedAlert"
                    >
                        <H4>{selectI18nStringById("archivedAlertTitle")}</H4>
                        <Text4>
                            {selectI18nStringById("archivedAlertMessage")}
                        </Text4>
                    </WarnAlert>
                )}

                {children}

                {crossLinkMode ? (
                    <article
                        {...attributesToProps(data.meta.pageAttributes)}
                        className={classes(styleScope, hasMaxWidth)}
                    >
                        <div
                            className={classes(
                                "body",
                                fontSansSerif && "fontSansSerif"
                            )}
                        >
                            <Highlight {...highlightProps}>
                                {data.content.body}
                            </Highlight>
                        </div>
                    </article>
                ) : (
                    <Selectable
                        anAnnotationEditorOpen={anAnnotationEditorOpen}
                        disabled={crossLinkMode}
                        onDragStart={onDragStart}
                        onSelectEnd={onSelectEnd}
                        onSelectClear={onSelectClear}
                        container={frameRef.current}
                        range={range}
                    >
                        <article
                            {...attributesToProps(data.meta.pageAttributes)}
                            className={classes(styleScope, hasMaxWidth)}
                            id={!crossLinkMode ? "main" : undefined}
                        >
                            <div
                                className={classes(
                                    "body",
                                    fontSansSerif && "fontSansSerif"
                                )}
                                style={{ "--increment": fontSize }}
                            >
                                <Highlight
                                    {...highlightProps}
                                    alternateBody={this.altContentBody}
                                >
                                    {data.content.body}
                                </Highlight>
                            </div>
                        </article>
                    </Selectable>
                )}
            </Frame>
        );
    }
}

const LDSSourceRendererContainer = (props) => {
    const fontSansSerif = useSelector(selectFontSansSerif);
    const fontSize = useSelector(selectFontSize);
    const location = useLocation();
    const selectBookmarksByPid = useSelectBookmarksByPid();
    const selectI18nStringById = useSelectI18nStringById();
    const activeAnnotationId = useSelector(selectActiveAnnotationId);
    const highlights = useSelector((state) =>
        selectHighlightsByDocId(state, location)
    );
    const noIndex = useSelector((state) =>
        selectNoIndexStatus(state, location)
    );

    return (
        <LDSSourceRenderer
            fontSansSerif={fontSansSerif}
            fontSize={fontSize}
            activeAnnotationId={activeAnnotationId}
            highlights={highlights}
            noIndex={noIndex}
            selectBookmarksByPid={selectBookmarksByPid}
            selectI18nStringById={selectI18nStringById}
            {...props}
        />
    );
};

export default LDSSourceRendererContainer;
