import bezierEasing from "bezier-easing";
import { getTopPid } from "../../../../util/utils";

export const BOOKMARK_HEIGHT = 24;
export const DEFAULT_OFFSET = 64;
export const DIVIDER_OFFSET = 56;
export const TOOLBAR_MAX_HEIGHT = 324;

// Some headers have an image or video above the title. This checks to see if there
// is enough space for the Toolbar to fit at the top without intersecting the title
const hasSpace = () => {
    const headerElement = document.querySelectorAll(
        "[data-aid-version] header"
    )[0];
    const headerTop = headerElement?.getBoundingClientRect().top - 20;

    const titleElement = getTopPid({ fullpage: true });
    const titleTop = titleElement?.getBoundingClientRect().top;

    const hasSpace = titleTop - headerTop - TOOLBAR_MAX_HEIGHT > 0;

    return hasSpace;
};

const getTopRangeAndElement = () => {
    let range = document.createRange();

    const element = getTopPid({ fullpage: true });
    element && range.selectNodeContents(element);

    return { element, range };
};

const calcBookmarkEndPosition = ({
    pidElement,
    range,
    selectBookmarksByPid,
}) => {
    if (!pidElement) {
        let rangeStartElement = range.startContainer.closest
            ? range.startContainer
            : range.startContainer.parentElement;

        pidElement = rangeStartElement?.closest(
            "[data-aid-version] [data-aid]"
        );
    }

    const pid = pidElement?.getAttribute("data-aid");

    let bookmarksForPid = selectBookmarksByPid(pid);

    const elementFontSize = pidElement
        ? window.getComputedStyle(pidElement).getPropertyValue("font-size")
        : 0;
    const bookmarkOffset = Math.max(parseInt(elementFontSize), BOOKMARK_HEIGHT);

    return bookmarksForPid.length
        ? Math.round(pidElement?.getBoundingClientRect().top + bookmarkOffset)
        : 0;
};

// Calulates where the active selection is or returns to the top of the current view
export const calcEndPosition = (range, selectBookmarksByPid) => {
    if (!range) return 0;

    let bookmarkEndPosition = calcBookmarkEndPosition({
        range,
        selectBookmarksByPid,
    });
    let rangeTop = Math.round(
        range.getBoundingClientRect().top - DIVIDER_OFFSET
    );

    return Math.max(bookmarkEndPosition, rangeTop);
};

// Calculates how far from the top of the article element the toolbar track should start
export const calcInitialOffset = (selectBookmarksByPid) => {
    // The archived alert pushes the content down so we take it's height into account when present
    const archivedAlert = document.querySelector("#archivedAlert");
    const archivedAlertHeight =
        archivedAlert?.getBoundingClientRect().height || 0;
    const alertOffset = archivedAlert
        ? archivedAlertHeight + DEFAULT_OFFSET
        : 0;

    if (hasSpace()) return DEFAULT_OFFSET + archivedAlertHeight;

    const contentEle = document.querySelector("#content");
    const contentPos = contentEle?.getBoundingClientRect().top;

    const { element: topPidElement, range: topPidRange } =
        getTopRangeAndElement();
    const bookmarkPos = calcBookmarkEndPosition({
        pidElement: topPidElement,
        range: topPidRange,
        selectBookmarksByPid,
    });
    const bookmarkDiff = bookmarkPos ? bookmarkPos - contentPos : 0;

    const topPidPos = Math.round(
        topPidElement?.getBoundingClientRect().top - DIVIDER_OFFSET
    );
    const topPidDiff = topPidPos
        ? topPidPos - contentPos + archivedAlertHeight
        : 0;

    return Math.max(bookmarkDiff, topPidDiff, DEFAULT_OFFSET, alertOffset);
};

export const calcDuration = (distance) => Math.log2(Math.abs(distance)) * 100;

export const timing = bezierEasing(0.4, 0.1, 0.25, 1);
