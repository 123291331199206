import isMobileJs from "ismobilejs";
import isBrowser from "is-in-browser";

let isMobile = isMobileJs();

// iPadOS 13 reports as if it's a mac desktop
if (
    isBrowser &&
    navigator.platform === "MacIntel" &&
    navigator.maxTouchPoints > 1
) {
    isMobile.any = true;
    isMobile.tablet = true;
    isMobile.apple = Object.assign(isMobile.apple, {
        device: true,
        tablet: true,
    });
}

export default isMobile;
