import { PureComponent } from "react";
import classes from "classnames";
import styles from "../TableOfContents.css";
import SmartLink from "../../SmartLink";
import Title from "./Title.js";

class Item extends PureComponent {
    render() {
        const {
            activeUri,
            className,
            id,
            indent = 0,
            titleNumber,
            seriesTitle,
            songNumber,
            subtitle,
            title,
            uri,
            onClick,
        } = this.props;
        const active = activeUri === uri;

        return (
            <SmartLink
                className={classes(
                    className,
                    styles.item,
                    active && styles.active
                )}
                id={id}
                style={{ paddingInlineStart: `${indent * 16}px` }}
                to={uri}
            >
                <Title
                    onClick={onClick}
                    active={active}
                    title={title}
                    subtitle={subtitle}
                    songNumber={songNumber}
                    seriesTitle={seriesTitle}
                    titleNumber={titleNumber}
                />
            </SmartLink>
        );
    }
}

export default Item;
