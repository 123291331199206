import styles from "./PrintOptions.css";
import { useDispatch, useSelector } from "react-redux";
import {
    selectLoggedIn,
    selectPrintFootnotes,
    selectPrintHighlights,
} from "../../selectors";
import {
    setPrintHighlights,
    setPrintFootnotes,
} from "../../actions/localSettings";
import analytics from "../../../util/analytics";
import { useSelectI18nStringById } from "../../../util/custom-hooks";
import ToggleOption from "../SidePanelManager/components/ToggleOption";
import {
    PrimaryButton,
    SecondaryButton,
} from "../../theming/components/eden-buttons";

export const PrintOptions = ({
    loggedIn,
    onCancel,
    onClose,
    printFootnotes,
    printHighlights,
    selectI18nStringById,
    setPrintFootnotes,
    setPrintHighlights,
}) => {
    const fireShareEvent = () => {
        analytics.fireShareEvent({
            component: {
                info: {
                    channel: "print",
                    link: window.location.href,
                },
            },
        });
    };

    const startPrint = () => {
        fireShareEvent();

        // Printing footnotes is defaulted to match if footnotes are hidden in the reader view.
        // That way file > print can match that setting. These overrides allow the menus to override
        // the global footnotes setting.
        document.body.classList.remove(
            "print-show-footnotes--override",
            "print-hide-footnotes--override"
        );
        printFootnotes
            ? document.body.classList.add("print-show-footnotes--override")
            : document.body.classList.add("print-hide-footnotes--override");

        // Close the modal before printing so the modal overflow cropping isn't affecting the print
        onClose && onClose();
        // give the modal close event a moment to do its thing before printing
        setTimeout(() => window.print(), 1);
    };

    const toggleFootnotes = () => {
        const show = !printFootnotes;

        setPrintFootnotes(show);
    };

    const toggleHighlights = () => {
        const show = !printHighlights;

        setPrintHighlights(show);
        show
            ? document.body.classList.remove("print-hide-highlights")
            : document.body.classList.add("print-hide-highlights");
    };

    return (
        <>
            <div className={styles.printOptions}>
                <ToggleOption
                    description={
                        !loggedIn &&
                        selectI18nStringById("printOptionsSignInPrompt")
                    }
                    disabled={!loggedIn}
                    onToggle={toggleHighlights}
                    optionId="disable-highlights"
                    title={selectI18nStringById("printOptionsPrintHighlights")}
                    value={printHighlights}
                />
                <ToggleOption
                    onToggle={toggleFootnotes}
                    optionId="include-footnotes"
                    title={selectI18nStringById("printOptionsPrintFootnotes")}
                    value={printFootnotes}
                />
            </div>
            <div className={styles.printButtons}>
                <SecondaryButton
                    onClick={onCancel}
                    small={true}
                    data-testid="print-cancel"
                >
                    {selectI18nStringById("printOptionsCancel")}
                </SecondaryButton>
                <PrimaryButton
                    onClick={startPrint}
                    small={true}
                    data-testid="print-confirm"
                >
                    {selectI18nStringById("printOptionsConfirm")}
                </PrimaryButton>
            </div>
        </>
    );
};

const PrintOptionsContainer = (props) => {
    const dispatch = useDispatch();
    const loggedIn = useSelector(selectLoggedIn);
    const printFootnotes = useSelector(selectPrintFootnotes);
    const printHighlights = useSelector(selectPrintHighlights);
    const selectI18nStringById = useSelectI18nStringById();

    return (
        <PrintOptions
            loggedIn={loggedIn}
            printFootnotes={printFootnotes}
            printHighlights={printHighlights}
            selectI18nStringById={selectI18nStringById}
            setPrintHighlights={(value) => dispatch(setPrintHighlights(value))}
            setPrintFootnotes={(value) => dispatch(setPrintFootnotes(value))}
            {...props}
        />
    );
};

export default PrintOptionsContainer;
