// TODO: The below disabled eslint rules were done quickly to get the code to compile. They should be reviewed and re-enabled or corrected as necessary.
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styles from "./ReaderViewHeader.css";
import {
    selectActivePanel,
    selectContentTemplateType,
    selectContentType,
    selectTocTitle,
} from "../../selectors";
import { useSelectI18nStringById } from "../../../util/custom-hooks";

import RenderHTML from "../RenderHTML";
import StudySetsButton from "../StudySetsButton";
import { iconButtonFactory } from "../IconButton";
import { Title6 } from "../../theming/components/eden-headings/eden-headings";
import {
    BookmarkOutline,
    BookPublished as BookPublishedIcon,
    MoreMenuVertical as KebabIcon,
    RelatedContent as RelatedContentIcon,
} from "@churchofjesuschrist/eden-icons";

const BookmarksButton = iconButtonFactory(BookmarkOutline);
const BookPublishedButton = iconButtonFactory(BookPublishedIcon);
const OptionsButton = iconButtonFactory(KebabIcon);
const RelatedContentButton = iconButtonFactory(RelatedContentIcon);

BookmarksButton.displayName = "BookmarksButton";
BookPublishedButton.displayName = "BookPublishedButton";
OptionsButton.displayName = "OptionsButton";
RelatedContentButton.displayName = "RelatedContentButton";

export const ReaderViewHeader = ({
    activePanel,
    catalogTitle,
    content,
    contentType,
    contentTemplateType,
    crossLinkMode,
    selectI18nStringById,
    toggleSidePanel,
    toggleTableOfContentsPanel,
}) => {
    const displayCatalogTitle =
        (contentType === "magazine" || contentType === "general-conference") &&
        contentTemplateType !== "contents/manifest";

    const handleClick = (e) => {
        if (e.key === "Enter" || e.type === "click") {
            toggleTableOfContentsPanel(true, "open");
        }
    };

    return (
        <header
            className={styles.readerViewHeader}
            data-testid="readerview-header"
        >
            <div className={styles.iconGroupStart}>
                <BookPublishedButton
                    onClick={handleClick}
                    title={selectI18nStringById("tableOfContentsIconTitleText")}
                />
            </div>
            <span className={styles.contentTitle} onClick={handleClick}>
                <RenderHTML>{content.meta && content.meta.title}</RenderHTML>
                {displayCatalogTitle && (
                    <Title6 className={styles.catalogTitle}>
                        {catalogTitle}
                    </Title6>
                )}
            </span>
            <div className={styles.iconGroupEnd}>
                {!crossLinkMode && (
                    <>
                        <StudySetsButton
                            className={
                                activePanel === "StudySetsPanel" &&
                                styles.activePanel
                            }
                            onClick={() => toggleSidePanel("StudySetsPanel")}
                            data-testid="study-sets-tab"
                        />
                        <BookmarksButton
                            className={
                                activePanel === "BookmarksPanel" &&
                                styles.activePanel
                            }
                            hiddenTitleText={selectI18nStringById(
                                "bookmarkManagerTitle"
                            )}
                            onClick={() => toggleSidePanel("BookmarksPanel")}
                            title={selectI18nStringById("bookmarkManagerTitle")}
                            data-testid="bookmarks-tab"
                        />
                        <RelatedContentButton
                            className={
                                activePanel === "RelatedContentPanel" &&
                                styles.activePanel
                            }
                            hiddenTitleText={selectI18nStringById(
                                "relatedContentIconTitleText"
                            )}
                            onClick={() =>
                                toggleSidePanel("RelatedContentPanel")
                            }
                            title={selectI18nStringById(
                                "relatedContentIconTitleText"
                            )}
                            data-testid="rca-tab"
                        />
                        <OptionsButton
                            className={
                                activePanel === "OptionsPanel" &&
                                styles.activePanel
                            }
                            hiddenTitleText={selectI18nStringById(
                                "optionsTitle"
                            )}
                            onClick={() => toggleSidePanel("OptionsPanel")}
                            title={selectI18nStringById("optionsTitle")}
                            data-testid="options-tab"
                        />
                    </>
                )}
            </div>
        </header>
    );
};

const ReaderViewHeaderContainer = (props) => {
    const location = useLocation();
    const activePanel = useSelector(selectActivePanel);
    const catalogTitle = useSelector((state) =>
        selectTocTitle(state, location)
    );
    const contentType = useSelector((state) =>
        selectContentType(state, location)
    );
    const contentTemplateType = useSelector((state) =>
        selectContentTemplateType(state, location)
    );
    const selectI18nStringById = useSelectI18nStringById();

    return (
        <ReaderViewHeader
            activePanel={activePanel}
            catalogTitle={catalogTitle}
            contentTemplateType={contentTemplateType}
            contentType={contentType}
            selectI18nStringById={selectI18nStringById}
            {...props}
        />
    );
};

export default ReaderViewHeaderContainer;
