import styles from "./Divider.css";
import classes from "classnames";

const Divider = ({ className, ...props }) => (
    <hr className={classes(styles.divider, className)} {...props} />
);

Divider.displayName = "Divider";

export default Divider;
