import { useContext, useCallback } from "react";
import styles from "./SelectableParagraph.css";
import { SelectedPidContext } from "./ParagraphSelectorProvider";
import classes from "classnames";

// TODO: use in LDSSourceRenderer's crossLinkTransform
// TODO: change to have a wrapping component that reads the context and passes down both the dispatch
// function and a boolean for whether the background should be drawn (basically a container).
// ...Or the click logic (and style?) can be in the wrapping component (container)
export const SelectableParagraph = ({ Component, pid, ...props }) => {
    const { selectedPids, dispatch } = useContext(SelectedPidContext);
    const selected = selectedPids.has(pid);

    const handleClick = useCallback(() => {
        dispatch({
            type: selected ? "remove" : "add",
            payload: pid,
        });
        // TODO: Disable below is temporary to have as little functionality change as possible in this PR
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    return (
        <Component
            {...props}
            className={classes(
                styles.selectableParagraph,
                selected && styles.selected,
                props.className && props.className
            )}
            onClick={handleClick}
        />
    );
};
